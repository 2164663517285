@import '../abstracts/variables.scss';
@import '../abstracts/mixins.scss';

.pb-key-heading {
    h1, h2, h3, h4 {
        line-height: 1.4em;
        span {
            color: $font-clr;
        }
    }
    h2, h3 {
        font-size: 57px;
        position: relative;
        white-space: nowrap;
        display: block;
        &:after {
            content: "";
            border: 4px solid $atlasred;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: inline-block;
            white-space: nowrap;
            transform: translateY(-32px) ;
        }
        @include bpr-media-breakpoint(down, md) {
            font-size: clamp(39px, 10.025vw, 57px);
            white-space: normal;
            &::after {
                display: none;
            }
        }
    }
    sup {
        margin-top: 4em;
        color: #909090;
        font-size: 11px;
        display: inline-block;
    }
    &__centre {
        text-align: center;
        .inner {
            margin-left: auto;
            margin-right: auto;
        }
    }
    @include bpr-media-breakpoint(down, md) {
        &__left {
            .inner {
                max-width: none;
                text-align: center;
            }
        }
	}
}